/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import Image from "../components/Image";
import {graphql, Link} from 'gatsby'
import Layout from "../components/layout"
import "./blog.css";
import {Grid} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import SEO from "../components/SEO";
import Banner from "../components/Banner";

export default class Blog extends React.Component {
    render() {
        return <Layout id="blog">
            <SEO title={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_title}
                 description={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
            />
            {
                this.props.data.wordpressPage.acf.banner &&
                <Banner
                    title={this.props.data.wordpressPage.acf.banner.title}
                    description={this.props.data.wordpressPage.acf.banner.description}
                    image={this.props.data.wordpressPage.acf.banner.image}
                />
            }

            <div className='content'>
                <h2 className='title-big'>{this.props.data.wordpressPage.acf.blog.title}</h2>
                <div>{this.props.data.wordpressPage.acf.blog.description}</div>

                <Grid container style={{marginTop: '2em'}} spacing={2}>
                    {
                        this.props.data.allWordpressPost.edges.map(edge =>
                            <Grid item lg={4}  spacing={2}>
                                <Link to={`/post/${edge.node.slug}`}>
                                    <Card className="card">
                                        {
                                            edge.node.acf.banner.image &&
                                            <Image image={edge.node.acf.banner.image}/>
                                        }
                                        <CardContent style={{padding: '2em'}}>
                                            <h3 className='title-big'>
                                                {edge.node.title}
                                            </h3>
                                            <div dangerouslySetInnerHTML={{ __html: edge.node.excerpt}}></div>
                                        </CardContent>
                                    </Card>
                                </Link>
                            </Grid>
                        )
                    }
                </Grid>
            </div>
        </Layout>
    }
}

export const pageQuery = graphql`
    query {
        wordpressPage(slug: {eq: "blog"}) {
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
            }
            acf {
                blog {
                    title
                    description
                }
                banner {
                    title
                    description
                    image {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
            }
        }
        allWordpressPost {
            edges {
                node {
                    id
                    slug
                    title
                    excerpt
                    acf {
                        banner {
                            title
                            description
                            image {
                               alt_text
title
description
localFile {
                                    childImageSharp {
                                        id
                                        fluid {
                                            base64
                                            tracedSVG
                                            aspectRatio
                                            src
                                            srcSet
                                            srcWebp
                                            srcSetWebp
                                            originalName
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

`;


